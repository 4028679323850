import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | cut copy paste | styleselect bold italic | bullist numlist blockquote h3 | link | image media | code help'

  if(props.minimal)
    toolbar_buttons = 'undo redo | cut copy paste | bold italic | link'

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     tinymceScriptSrc="/tinymce/tinymce.min.js"
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
     initialValue={props.content}
     init={{
       height: props.height,
       menubar: false,
       plugins: [
         'image', 'link', 'code', 'lists', 'advlist', 'wordcount', 'media', 'help', 'blockquote',
       ],
       formats: {
          subtitle: { block: 'h2' }
        },
        style_formats: [
          { title: 'Podnaslov', format: 'subtitle' }
        ],
       toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
