$(document).ready(function(){
  var $hamburger = $(".hamburger");
  $hamburger.click(function(e) {
    $hamburger.toggleClass("is-active");
    $("#menu").fadeToggle(200);

    if ($hamburger.hasClass("is-active")) {
      $(".hamburger").css({
        "position": "fixed",
        "top": "20px",
        "right": "20px"
      });
      $("body").css("overflow-y", "hidden");

    } else {
      $(".hamburger").css({
        "position": "relative",
        "top": "0px",
        "right": "0px"
      });
      $("body").css("overflow-y", "auto");
    }
  });
});
