require('lightbox2')
require('jquery-ui-bundle')
require('jquery.cookie')

$(document).ready(function() {

  $("#alert_close img").on("click", function() {
    $(".alert").slideUp(100);
    $.cookie('booksa_alert_close', 'true', { path: '/' });
  })

  var btn = $('.back_to_top_btn');
  $(window).scroll(function() {
    if($(window).scrollTop() > 1000) {
      btn.fadeIn(100);
    } else {
      btn.fadeOut(100);
    }
  });

  $('.back_to_top_btn').on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });

  $('.calendar-holder').on('click', '.calendar-heading a', function(e){
    e.preventDefault();
    $('.simple-calendar').css('opacity', 0.4);
    $.ajax({
      url: this
    }).done(function(data) {
      $('.calendar-holder').html(data);
      $('.simple-calendar').css('opacity', 1);
    });
  });

  $('.calendar-holder').on('click', '.calendar_link', function(e){
    e.preventDefault();
    const id = $(this).attr('data-event-id');
    $.ajax({
      url: 'event/' + id
    }).done(function(data) {
      $('.event_holder').html(data);
    });
  });

  $('body').on('click', '.ajax_link', function(e){
    e.preventDefault();
    const url = $(this).attr('href')
    const target = $(this)
    $.ajax({
      url: url
    }).done(function(data) {
      target.hide()
      target.prev().after(data);
    });
  });

  $('.cookies-eu button').click(function(e){
    e.preventDefault();
    $.cookie('booksa_hr_cookie_consented', 'true', { path: '/', expires: 365 });
    $('.cookies-eu').fadeOut();
  });

});
