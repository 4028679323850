$(document).ready(function(){
  $(".select_box_title").click(function(event){
    $(this).next().slideToggle(200);
    event.stopPropagation();
  });
  $(".category_switch li a").each(function(i, item){
    if($(item).attr("data-selected") === "true"){
      $(".select_box_title strong").text($(item).text());
    }
  });
});

$(document).on("click", function(event) {
  var $trigger = $(".select_box_title");
  if($trigger !== event.target){
    $(".select_box_title").next().slideUp(200);
  }
});
