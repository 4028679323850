$(document).ready(function(){

/*
  $(window).resize(function() {
    if ($(window).width() >= 770) {
      $(".booksa_logo_container").removeClass("booksa_logo_container_display");
    }
  });
*/
  $("#search_icon").click(function(e) {
    e.preventDefault();
    $("header .nav").toggleClass("nav_display");
    $(".search_bar").toggle();
    $(".search_bar").find($(".search_input")).focus();
    $("#search_icon").toggleClass("active_brown");

    if ($(window).width() <= 770) {
      $(".booksa_logo_container").toggleClass("booksa_logo_container_display");
      $(".header_container .col_12").toggleClass("flex_modify");
    }
    });
});
